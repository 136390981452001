// Generated by Framer (2ca17d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["QNeRurAv6", "dAtjxpnJL", "jjuPRgvYF", "c55uTZk9F"];

const serializationHash = "framer-Sz041"

const variantClassNames = {c55uTZk9F: "framer-v-1axrrf7", dAtjxpnJL: "framer-v-1ru63c0", jjuPRgvYF: "framer-v-3kieh0", QNeRurAv6: "framer-v-1sv9cin"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Close dark": "c55uTZk9F", "Menu dark": "jjuPRgvYF", Close: "dAtjxpnJL", Menu: "QNeRurAv6"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "QNeRurAv6", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "QNeRurAv6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapc2fruv = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {const res = await XKaY3MrGu(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1sv9cin", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"QNeRurAv6"} onTap={onTapc2fruv} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({c55uTZk9F: {"data-framer-name": "Close dark"}, dAtjxpnJL: {"data-framer-name": "Close"}, jjuPRgvYF: {"data-framer-name": "Menu dark"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1kb921y"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"b8kRbeP3M"} style={{backgroundColor: "var(--token-126446c2-eb0e-49df-8077-621c8ac9b28d, rgb(255, 255, 255))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{c55uTZk9F: {backgroundColor: "var(--token-2eb96549-150d-4b57-988b-cf945889dc2a, rgb(37, 74, 128))", rotate: -45}, dAtjxpnJL: {rotate: -45}, jjuPRgvYF: {backgroundColor: "var(--token-2eb96549-150d-4b57-988b-cf945889dc2a, rgb(37, 74, 128))", rotate: 0}}}/><motion.div className={"framer-1s326wm"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"M1YIMdtsT"} style={{backgroundColor: "var(--token-126446c2-eb0e-49df-8077-621c8ac9b28d, rgb(255, 255, 255))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{c55uTZk9F: {backgroundColor: "var(--token-2eb96549-150d-4b57-988b-cf945889dc2a, rgb(37, 74, 128))", rotate: 45}, dAtjxpnJL: {rotate: 45}, jjuPRgvYF: {backgroundColor: "var(--token-2eb96549-150d-4b57-988b-cf945889dc2a, rgb(37, 74, 128))", rotate: 0}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Sz041.framer-1ddm7y0, .framer-Sz041 .framer-1ddm7y0 { display: block; }", ".framer-Sz041.framer-1sv9cin { cursor: pointer; height: 38px; overflow: hidden; position: relative; width: 38px; }", ".framer-Sz041 .framer-1kb921y { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(60.526315789473706% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-Sz041 .framer-1s326wm { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(39.473684210526336% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-Sz041.framer-v-1ru63c0.framer-1sv9cin, .framer-Sz041.framer-v-3kieh0.framer-1sv9cin, .framer-Sz041.framer-v-1axrrf7.framer-1sv9cin { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 38px); }", ".framer-Sz041.framer-v-1ru63c0 .framer-1kb921y, .framer-Sz041.framer-v-1ru63c0 .framer-1s326wm, .framer-Sz041.framer-v-1axrrf7 .framer-1kb921y, .framer-Sz041.framer-v-1axrrf7 .framer-1s326wm { top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 38
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dAtjxpnJL":{"layout":["fixed","fixed"]},"jjuPRgvYF":{"layout":["fixed","fixed"]},"c55uTZk9F":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerA3fn_0GuY: React.ComponentType<Props> = withCSS(Component, css, "framer-Sz041") as typeof Component;
export default FramerA3fn_0GuY;

FramerA3fn_0GuY.displayName = "Icon";

FramerA3fn_0GuY.defaultProps = {height: 38, width: 38};

addPropertyControls(FramerA3fn_0GuY, {variant: {options: ["QNeRurAv6", "dAtjxpnJL", "jjuPRgvYF", "c55uTZk9F"], optionTitles: ["Menu", "Close", "Menu dark", "Close dark"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerA3fn_0GuY, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})